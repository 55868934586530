<template>
  <div class="main-body">
    <div class="info-box">
      <h1>Multimorbidities among common diseases in the UK Biobank</h1>
    </div>
    <ul class="count-box">
      <li v-for="(item,index) in countsData" :key="index">
        <div>{{item.name}}</div>
        <div>{{item.count|countFilter}}</div>
      </li>
    </ul>
    <div class="description-box">
      <p>Manuscript: A global overview of genetically interpretable multimorbidities among common diseases in the UK Biobank
      </p>
      <p>https://genomemedicine.biomedcentral.com/articles/10.1186/s13073-021-00927-6
      </p>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
Vue.filter("countFilter", function (val) {
  return val;
});
export default {
data:() => {
  return {
    countsData: [
      {name: 'Participants', count: '410,309'},
      {name: 'Diseases', count: '439'},
      {name: 'Multimorbidities', count: '11,285'},
      {name: 'Genetically Interpretable Multimorbidities', count: '3,766'},
    ]
  }
}
}
</script>

<style lang="less" scoped>
.count-box {
    display: flex;
    width: 60%;
    margin: 0 auto;
    padding-left: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    li {
      flex-basis: 20%;
      border-right: 1px solid #000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 17px;
      div:last-child {
        margin-top: 10px;
        font-weight: bolder;
        font-size: 30px;
      }
    }
    li:last-child{
      border-right: none;
    }
  }
  .info-box{
    padding-top: 13%;
    height: 140px;
    line-height: 50px;

    h1 {
      text-shadow: 1px 1px 2px #777777;
      font-size: 32px;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
      Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
  }
  .main-body{
    background-image: url("../assets/molecule-bg-graph.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    height: 100%;
  }
  h1{
    margin: 0;
  }
  .description-box{
    margin-top: 60px;
  }
</style>
